import { render, staticRenderFns } from "./LandingFooter.vue?vue&type=template&id=41b5ac00&scoped=true&"
import script from "./LandingFooter.vue?vue&type=script&lang=ts&"
export * from "./LandingFooter.vue?vue&type=script&lang=ts&"
import style0 from "./LandingFooter.vue?vue&type=style&index=0&id=41b5ac00&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "41b5ac00",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {LandingFooterContacts: require('/app/components/landing/footer/LandingFooterContacts.vue').default,LandingFooterCopy: require('/app/components/landing/footer/LandingFooterCopy.vue').default})

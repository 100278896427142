// Функционал для обращения к localStorage и sessionStorage

// Можно сменить префикс
const PREFIX = "app"
const prefix = (k: string) => `${PREFIX}.${k}`

// Определяйте ключи тут
export const storageKeys = {
  previousToken: "previousToken",
  prefs: "prefs",
  pwaHintShown: "pwaHintShown",
}

type StorageName = "localStorage" | "sessionStorage"

function get (storage: StorageName) {
  return function (key: string) {
    try {
      return JSON.parse(window[storage].getItem(prefix(key)) || "")
    } catch { return "" }
  }
}

function set (storage: StorageName) {
  return function (key: string, val: Parameters<typeof JSON.stringify>[0]) {
    return window[storage].setItem(prefix(key), JSON.stringify(val))
  }
}

function remove (storage: StorageName) {
  return function (key: string) {
    return window[storage].removeItem(prefix(key))
  }
}

const ls = "localStorage"
export const getLs = get(ls)
export const setLs = set(ls)
export const removeLs = remove(ls)

const ss = "sessionStorage"
export const getSs = get(ss)
export const setSs = set(ss)
export const removeSs = remove(ss)

const title = "ИМПУЛЬС"
const lang = "ru"
export const meta = {
  title,
  lang,
  social: [
    { hid: "og:locale", property: "og:locale", content: lang },
    { hid: "twitter:title", property: "twitter:title", content: title },
  ],
}

export const getOgImgMeta = (src: string) => ([
  { hid: "og:image", property: "og:image", content: src },
  { hid: "twitter:image", property: "twitter:image", content: src },
  { hid: "vk:image", property: "vk:image", content: src },
  // Необходимо для вк
  { hid: "og:image:width", property: "og:image:width", content: "968" },
  { hid: "og:image:height", property: "og:image:height", content: "504" },
])

import Vue from "vue"
import Element from "element-ui"
import elLocale from "element-ui/lib/locale"

Vue.use(Element)

const getRuLocale = async () =>
  (await import("element-ui/lib/locale/lang/ru-RU")).default
const getEnLocale = async () =>
  (await import("element-ui/lib/locale/lang/en")).default

const setLocale = async locale =>
  elLocale.use(await (locale === "en" ? getEnLocale : getRuLocale)())

export default ({ app }) => {
  setLocale(app.i18n.locale)
  app.i18n.onLanguageSwitched = (_oldLocale, newLocale) => setLocale(newLocale)
}

import sanitizeHtml, { defaults } from "sanitize-html"
import { marked } from "marked"

export const md = (raw: any): string => raw
  ? sanitizeHtml(marked(raw), {
    allowedTags: defaults.allowedTags.concat(["img"]),
    allowedAttributes: {
      ...defaults.allowedAttributes,
      // "*": ["style"],
    },
  })
  : ""

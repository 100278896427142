import { storageKeys, getLs } from "@/utils/storage"

const plugin: NuxtPlugin = ({ store }) => {
  // Имперсонализация
  store.commit("user/SET_PREVIOUS_TOKEN", getLs(storageKeys.previousToken))

  // Локальные настройки
  store.dispatch("user/FETCH_PREFS")

  // Определяем показывать или нет кнопку "Назад"
  store.commit("app/SET_HISTORY_LEN", window.history.length)

  // PWA модалка
  window.addEventListener("beforeinstallprompt", async (e) => {
    e.preventDefault()
    store.commit("app/SET_BEFOREINSTALL_EVENT", e)
    const { default: UAParser } = await import("ua-parser-js")
    const isMobile = (new UAParser()).getDevice().type === "mobile"
    const isPwaModalWasShown = getLs(storageKeys.pwaHintShown)
    store.commit("app/SET_IS_PWA_MODAL_OPEN", isMobile && !isPwaModalWasShown)
  }, { once: true })
}
export default plugin

import type { User } from "@/types/api/person"

export function changeUrl (url: string) {
  window.history.replaceState({}, "", url)
}

export const isUserAdmin = (user: User) => Boolean(user) && [
  "admin",
  "superadmin",
].some(r => user.roles.includes(r))

export function getFailToken (
  error: NuxtContext["error"], i18n: NuxtContext["i18n"],
) {
  return () => error({
    statusCode: 404, message: i18n.t("error.error") as string,
  })
}

export function onLoad (cb: Function, delay = 1) {
  const delayedCb = () => setTimeout(() => cb(), delay)
  if (document.readyState === "complete") delayedCb()
  else window.addEventListener("load", delayedCb, { once: true })
}

export function scrollToTop () {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: "smooth",
  })
}


import Vue from "vue"
import { mapState } from "vuex"
import pickBy from "lodash/pickBy"

export default Vue.extend({
  computed: {
    ...mapState("app", ["event"]),
    socials (): Record<string, string> {
      return pickBy(this.event?.links || {}, Boolean)
    },
  },
})

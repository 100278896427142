
import { mapState, mapGetters } from "vuex"
import layoutMixin from "@/mixins/layout"
import { storageKeys, setLs } from "@/utils/storage"

interface BeforeInstallEvent extends Event {
  readonly platforms: string[]
  readonly userChoice: Promise<{
    outcome: "accepted" | "dismissed",
    platform: string
  }>
  prompt(): Promise<void>
}

export default layoutMixin.extend({
  middleware: "auth",

  computed: {
    ...mapState("app", ["beforeInstallEvent", "isPwaModalOpen"]),
    ...mapState("user", ["unreadMessagesCount"]),
    ...mapGetters("user", ["user", "isPayable"]),

    isPwaModalOpen_: {
      get (): boolean { return this.isPwaModalOpen },
      set (isPwaModalOpen_: boolean) {
        this.$store.commit("app/SET_IS_PWA_MODAL_OPEN", isPwaModalOpen_)
      },
    },

    isPaymentVisible (): boolean {
      return this.isPayable
    },

    isRecommendedVisible (): boolean {
      return this.user.status.alias !== "speaker"
    },

    tabs (): string[] {
      const tabs = [
        "profile",
        "calendar",
        "chat",
      ]

      if (this.isPaymentVisible) tabs.push("payment")
      if (this.isRecommendedVisible) tabs.push("recommended")

      return tabs
    },

    tab: {
      get (): string {
        const { tabs } = this
        return tabs.find(t => this.$route.path.includes(t)) || tabs[0]
      },
      set (tab: string) {
        // FIXME
        if (!this.$route.path.includes(tab))
          this.$router.push(this.localePath(`/user/${tab}`))
      },
    },
  },

  mounted () {
    if (this.isPwaModalOpen_) setLs(storageKeys.pwaHintShown, true)
  },

  methods: {
    showPwaPrompt () {
      const beforeInstallEvent =
        this.beforeInstallEvent as Nullable<BeforeInstallEvent>
      if (!beforeInstallEvent) return
      beforeInstallEvent.prompt()
      beforeInstallEvent.userChoice.then((_choiceResult) => {
        // if (choiceResult.outcome === "accepted")
        //   console.log("User accepted the A2HS prompt")
        // else
        //   console.log("User dismissed the A2HS prompt")
        this.isPwaModalOpen_ = false
      })
    },
  },
})

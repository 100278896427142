
import Vue from "vue"
import throttle from "lodash/throttle"
import { scrollToTop } from "@/utils/_"

export default Vue.extend({
  data: () => ({
    scrollY: 0,
    isVisible: false,
  }),

  mounted () {
    const { onScroll } = this
    onScroll()
    window.addEventListener("scroll", onScroll, { passive: true })
  },
  beforeDestroy () {
    window.removeEventListener("scroll", this.onScroll)
  },

  methods: {
    scrollToTop,

    onScroll: throttle(function (this: any) {
      const { scrollY } = window
      this.isVisible = scrollY < 10 || scrollY <= this.scrollY
      this.scrollY = scrollY
    }, 100),
  },
})
